<template>
  <div id="app">

      <v-row>

          <v-col cols="5" lg="2">
            <v-menu v-model="menu" :close-on-content-click="false">
              <v-text-field
                :value="date_debut"
                slot="activator"
                v-model="date_debut"
                prepend-icon="mdi-calendar"
                clearable label="Date debut"
               ></v-text-field>
                <v-v-date-picker v-model="date_debut" @change="menu = false" ></v-v-date-picker>
            </v-menu>

          </v-col>
          <v-col cols="5" lg="2">
            <v-menu>
              <v-text-field
                :value="date_fin"
                slot="activator"
                v-model="date_fin"
                prepend-icon="mdi-calendar"
                label="Date fin"
               ></v-text-field>
                <v-v-date-picker v-model="date_fin" ></v-v-date-picker>
            </v-menu>

          </v-col>
          <v-col>
              <v-btn color="primary" dark class="mb-2" @click="readAll">
                  Recherche
              </v-btn>

          </v-col>


          <v-col v-if="null">
              <v-btn color="green" dark class="mb-2" @click="Actulisation()">
                  Actualiser
              </v-btn>

          </v-col>

      </v-row>

      <v-data-table :headers="headers" :items="list"  hide-actions  class="elevation-1" color="primary" >
          <template v-slot:top>
              <v-toolbar flat>
                  <v-toolbar-title>HISTORIQUE DES TRANSACTIONS PM</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>

              </v-toolbar>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="Actulisation">
                Actualiser la liste
            </v-btn>
        </template>
      </v-data-table>

  </div>
</template>

  <script>
  //import api from '../../../serviceApi/apiService'
  import api from '@/serviceApi/apiService'

  export default {
      name: "pm_historique",
      data: () => ({

          headers: [

              {
                  text: "Time",
                  align: "start",
                  sortable: false,
                  value: "time",
                  divider: true,
                  width: '10px'
              },
              {
                  text: "Type",
                  align: "start",
                  sortable: false,
                  value: "type",
                  divider: true,
                  width: '50px'
              },

              {

                  text: "Batch",
                  align: "start",
                  sortable: false,
                  value: "batch",
                  divider: true,
                  width: '20px'
              },
              {

                  text: "Currency",
                  align: "start",
                  sortable: false,
                  value: "currency",
                  divider: true,
                  width: '20px'
              },
              {
                  text: "Amount",
                  align: "start",
                  sortable: false,
                  value: "amount",
                  divider: true,
                  width: '350px'
              },

              {
                  text: "Fee",
                  value: "fee",
                  divider: true,
                  width: '10px'
              },
              {
                  text: "Payer Account",
                  value: "payer_account",
                  divider: true,
                  width: '2%'
              },
              {
                  text: "Payee Account",
                  value: "payee_account",
                  divider: true,
                  width: '2%'
              },
              {
                  text: "Payment ID",
                  value: "payment_id",
                  divider: true,
                  width: '2%'
              },
              {
                  text: "Memo",
                  value: "memo",
                  divider: true,
                  width: '2%'
              },

          ],

          date_debut: null,
          date_fin: null,
          menu: false,

          list: [],


      }),

      created() {
          // put today date in date_debut and date_fin in format yyyy-mm-dd
          let today = new Date();
          let dd = today.getDate();
          let mm = today.getMonth()+1; //January is 0!
          let yyyy = today.getFullYear();
          this.date_debut = yyyy+'-'+mm+'-'+dd;
          this.date_fin = yyyy+'-'+mm+'-'+dd;
          this.readAll();
      },
      methods: {



         // get pm historique
         readAll: async function () {


              // get day for date_debut
              let startDay = this.date_debut.split('-')[2];
              let startMonth = this.date_debut.split('-')[1];
              let startYear = this.date_debut.split('-')[0];

              let endDay = this.date_fin.split('-')[2];
              let endMonth = this.date_fin.split('-')[1];
              let endYear = this.date_fin.split('-')[0];



               const data = await   api.post_data('https://apiservice.meta-change.io/api/pm/history', {
                      startday: startDay,
                      startmonth: startMonth,
                      startyear: startYear,
                      endday: endDay,
                      endmonth: endMonth,
                      endyear: endYear,
                  });
              //console.log(data.out);
              this.list = data.out;
          },



      },
  };
  </script>
